#root {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: stretch;
    width: 100vw;
    min-height: 100vh;
    overflow: hidden;
    
    @include media-tablet-up {
        padding-right: var(--common-scroll-width);
    }

    @media (max-width: 768px) {
        flex-direction: row;
    }
}

html, body {
    width: 100%;
    height: 100%;
    background: $color-background;
}

body {
    overflow-x: hidden;
}

* {
    margin: 0;
    box-sizing: border-box;
    border-spacing: 0;

    outline-color: $focus-outline-color;
    outline-offset: $focus-outline-distance;
    outline-width: $focus-outline-width;

    font-family: var(--default-font-name, "SF Pro Display"), sans-serif, "currencies", auto;
}

hr {
    border: none;
    border-top: 1px solid #e2e8ef;
    margin: 5px 0;
}

.link {
    display: inline;
    border: none;
    background: none;
    font-size: inherit;
    font-weight: inherit;
    padding: 0;
    text-decoration: none;

    &:not(:disabled) {
        cursor: pointer;
    }

    &--dotted {
        border-bottom: 1px dotted $color-primary;
        margin-bottom: -1px;

        &:not(:disabled) {
            &:hover, &:active {
                text-decoration: none !important;
                border-bottom-style: solid;
            }
        }
    }

    &--underline {
        text-decoration: underline;
    }
}

a, a:visited, .link {
    color: $color-primary;
    text-decoration: none;

    &:not(:disabled) {
        &:hover, &:active {
            color: darken($color-primary, 10%);
            text-decoration: underline;
        }
    }

    &:focus {
        outline: $focus-outline-color;
    }

    &.link--black {
        color: $color-title-active;
        font-weight: 500;
        font-size: $font-size--small;

        &:hover:not(:disabled) {
            color: lighten($color-title-active, 35%);
            text-decoration: underline;
        }
    }

    &.link--white {
        color: $color-off-white;
        font-weight: 500;

        &:hover:not(:disabled) {
            color: lighten($color-off-white, 35%);
            text-decoration: underline;
        }
    }

    &.link--bold {
        color: $color-title-active;
        font-weight: 600;
        font-size: $font-size--small;
    
        &:hover:not(:disabled) {
            color: lighten($color-title-active, 35%);
            text-decoration: underline;
        }
    }

    &.link--underlined {
        text-decoration: underline;
    }
}

.chart-label {
    padding: 5px 0;
}

jdiv#jvLabelWrap {
    left: calc(100vw - 340px - var(--common-scroll-width));
    width: 300px;
}

jdiv.__jivoMobileButton {
    z-index: 990 !important;
}

/* Disable browser autocomplete styles */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: inherit;
  -webkit-text-fill-color: inherit;
  box-shadow: 0 0 0px 1000px #F3F4F5FF inset;
  transition: inherit;
}

.grecaptcha-badge {
    opacity: 0.0 !important;
    pointer-events: none;
}
