@use "sass:math";
@import "./tools";

@mixin grid-column-px($width, $marginSize) {
    display: flex;
    flex-direction: column;
    width: $width;
    min-width: $width;
    margin-right: $marginSize;

    &:last-child {
        margin-right: 0;
    }
}

@mixin grid-column-flex($flex, $marginSize) {
    display: flex;
    flex-direction: column;
    flex: $flex;
    margin-right: $marginSize;
    overflow: auto;

    &:last-child {
        margin-right: 0;
    }
}

@mixin grid-column($width, $marginSize) {
    display: flex;
    flex-direction: column;
    width: calc(#{math.div(100%, 12) * $width} - #{math.div($marginSize, 2)});
    margin-right: $marginSize;

    &:last-child {
        margin-right: 0;
    }
}

@mixin grid-markup($marginSize) {
    .grid-column-max {
        @include grid-column-flex(1, $marginSize);
    }

    .grid-column-100px {
        @include grid-column-px(100px, $marginSize);
    }

    .grid-column-150px {
        @include grid-column-px(150px, $marginSize);
    }

    .grid-column-250px {
        @include grid-column-px(250px, $marginSize);
    }

    .grid-column-300px {
        @include grid-column-px(300px, $marginSize);
    }

    .grid-column-380px {
        @include grid-column-px(380px, $marginSize);
    }

    .grid-column-8 {
        @include grid-column(8, $marginSize);
    }
    
    .grid-column-6 {
        @include grid-column(6, $marginSize);
    }

    .grid-column-4 {
        @include grid-column(4, $marginSize);
    }
}

.grid-row-wide {
    $marginSize: 20px;

    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1400px) {
        display: block;
    }

    @media screen and (min-width: 1401px) {
        @include grid-markup($marginSize);
    }
}

.grid-row {
    $marginSize: 20px;

    display: flex;
    justify-content: space-between;

    @include media-tablet {
        display: block;
    }

    @include media-tablet-up {
        @include grid-markup($marginSize);
    }
}

.grid-row-tablet {
    $marginSize: 20px;

    display: flex;
    justify-content: space-between;

    @include media-mobile {
        display: block;
    }

    @include media-mobile-up {
        @include grid-markup($marginSize);
    }
}

.grid-row-tablet-zero {
    $marginSize: 0px;

    display: flex;
    justify-content: space-between;

    @include media-mobile {
        display: block;
    }

    @include media-mobile-up {
        @include grid-markup($marginSize);
    }
}

.grid-row-thin {
    $marginSize: 4px;

    display: flex;
    justify-content: space-between;

    @include media-mobile-up {
        @include grid-markup($marginSize);
    }

    @include media-mobile {
        display: block;
    }
}

.grid-row--v-center {
    align-items: center;
}

.grid-column--border-left {
    border-left: 1px solid #e0e0e0;
}

.grid-row--border-bottom {
    border-bottom: 1px solid #e0e0e0;
    margin-bottom: 20px;
}