$OpenSansPath: '~fonts/OpenSans';
$SFProDisplayPath: '~fonts/SFProDisplay';
$FontsPath: '~fonts/';

// @import "fonts/currencies";
// @import "fonts/sf-pro-display";

@import "~@ui/styles/tools";
@import "~@ui/styles/helpers";

// "Open Sans" font from lib overwrites "SFProDisplay" font from layout.tsx
@import "~@ui/styles/base";
@import "~@ui/styles/typography";
@import "~@ui/styles/grid";

html {
    --common-scroll-width: 15px;
}

@include media-mobile {
    html {
        --common-scroll-width: 0px;
    }
}

body {
    // Use this trick to solve issues on opening modal that hides scroll with overflow: hidden
    width: 100vw;
    padding-right: var(--common-scroll-width);
}

// Disable Opera detach video button
#detach-button-host {
    display: none !important;
}

.__jivoMobileButton > jdiv {
    margin-left: 10px !important;
}