h2 {
    font-size: 22px;
}

h3 {
    font-size: 20px;
    font-weight: 600;
}

.align-content-start   { align-content: flex-start !important; }
.align-content-end     { align-content: flex-end !important; }
.align-content-center  { align-content: center !important; }
.align-content-between { align-content: space-between !important; }
.align-content-around  { align-content: space-around !important; }
.align-content-stretch { align-content: stretch !important; }

.justify-content-start   { justify-content: flex-start !important; }
.justify-content-end     { justify-content: flex-end !important; }
.justify-content-center  { justify-content: center !important; }
.justify-content-between { justify-content: space-between !important; }
.justify-content-around  { justify-content: space-around !important; }

dl {
  display: flex;
  flex-wrap: wrap;
  margin: -10px auto;

  dt, dd {
    margin: 10px 0;
  }

  dt {
    flex: 0 0 25%;
    max-width: 25%;
    font-weight: 700;
  }

  dd {
    flex: 0 0 75%;
    max-width: 75%;
  }
}

b {
    font-weight: 600;
}

p {
    margin-bottom: 20px;
    font-size: $font-size--normal;
    line-height: 23px;
    letter-spacing: 0.3px;
    font-weight: 300;
    color: $color-title-active;

    &.danger {
        color: $color-red--text;

        b {
            color: $color-red--text;
        }
    }
}

ol, ul {
    margin: 20px 0;
    padding-left: 18px;
    color: $color-title-active;

    & > li {
        font-weight: 300;
        letter-spacing: 0.3px;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 4px;
    }    
}

.code {
    font-family: 'Courier New', Courier, monospace;
    font-size: 16px;
    line-height: 20px;
    color: #000;
    background: #F5F5F5;
    padding: 2px 6px 1px;
    display: inline-block;
    border: 1px solid #E9E9E9;
    border-radius: 2px;
}

.text-container {
    max-width: 910px;
}

.text-light {
    color: $color-off-white;
}

.text-primary {
    color: $color-primary;
}

.text-secondary {
    color: $color-body;
}

.text-muted {
    font-size: $font-size--normal;
    line-height: $font-size--normal*1.4;
    font-weight: 300;
    color: $color-body;
}

.text-highlighted {
    font-size: $font-size--normal;
    line-height: $font-size--normal*1.4;
    font-weight: 500;
    color: $color-primary;
}

.text-success {
    color: $color-green;
}

.text-danger {
    color: $color-error;
}

.text-warning {
    color: $color-warning2;
}

.text-small {
    font-size: 12px !important;
}

.text-normal {
    font-size: 14px !important;
}

.text-large {
    font-size: 16px !important;
}

.text-fw-400 {
  font-weight: 400;
}

.text-fw-500 {
  font-weight: 500;
}

.text-fw-600 {
  font-weight: 600;
}

.text-fw-700 {
  font-weight: 700;
}

.list-dash {
    padding: 0;
    list-style: none;

    li {
        &::before {
            display: inline-block;
            content: '—';
            color: $color-green;
            font-size: 18px;
            line-height: 18px;
            margin-right: 8px;
        }

        font-size: 14px;
        line-height: 18px;
        margin-bottom: 5px;
    }
}

.list-dash--black {
        padding: 0;
        list-style: none;

        li {
            &::before {
                display: inline-block;
                content: '–';
                margin-right: 10px;
            }
            
            font-size: 17px;
            line-height: 27px;
        }
}